<template>
  <div class="q-pa-sm">
    <c-table
      title="즉시조치"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!param.disabled"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!param.disabled" label="LBLSAVE" icon="save" />
          <c-btn v-if="editable&&!param.disabled" label="LBLADD" icon="add" @btnClicked="add1" />
        </q-btn-group>
      </template>
    </c-table>
    
    <c-table
      class="q-mt-md"
      title="LBLIMPRREQUEST"
      :columns="grid2.columns"
      :gridHeight="grid2.height"
      :data="grid2.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!param.disabled"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!param.disabled" label="LBLSAVE" icon="save" />
          <c-btn v-if="editable&&!param.disabled" label="LBLADD" icon="add" @btnClicked="add2" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'impr-tab',
  props: {
    param: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: 'LBLLOCATION',
            align: 'center',
            style: 'width:200px',
            type: 'text',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '조치사항',
            align: 'center',
            style: 'width:400px',
            type: 'textarea',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '조치결과',
            align: 'center',
            style: 'width:400px',
            type: 'textarea',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '조치자',
            align: 'center',
            style: 'width:150px',
            type: 'user',
            userId: 'userId',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '조치일',
            align: 'center',
            style: 'width:200px',
            type: 'date',
            sortable: true,
          },
        ],
        data: [],
        height: '350px'
      },
      grid2: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '요청내용',
            align: 'center',
            style: 'width:400px',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '조치결과',
            align: 'center',
            style: 'width:400px',
            sortable: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '조치부서',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '조치예정일',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '조치완료일',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
        height: '362px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList1();
      this.getList2();
    },
    getList1() {
      this.grid.data = [
        { col1: 'XX동 2층 계단', col2: '돌이 있음', col3: '치움', col4: '홍길동', userId: '1', col5: '2021-06-25' },
      ]
    },
    getList2() {
      this.grid2.data = [
        { col1: '접수', col2: 'XXX 요청', col3: '', col4: '안전환경팀', deptCd: '1', col5: '2021-06-28', col6: '' },
      ]
    },
    add1() {
      this.grid.data.splice(0, 0, {
        col1: '',
        col2: '',
        col3: '',
        col4: '',
        col5: '',
        userId: '',
      })
    },
    add2() {
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        disabled: this.param.disabled,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data !== 'CLOSE') {
        // this.grid2.data.splice(0, 0, {
        //   col1: '개선요청',
        //   col2: '',
        //   col3: '',
        //   col4: '',
        //   col5: '',
        //   col6: '',
        //   deptCd: '',
        // })
      }
    },
  }
};
</script>
