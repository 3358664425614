var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "q-pa-sm" },
    [
      _c(
        "c-table",
        {
          attrs: {
            title: "즉시조치",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.grid.data,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && !_vm.param.disabled,
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.param.disabled
                    ? _c("c-btn", { attrs: { label: "LBLSAVE", icon: "save" } })
                    : _vm._e(),
                  _vm.editable && !_vm.param.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.add1 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "c-table",
        {
          staticClass: "q-mt-md",
          attrs: {
            title: "LBLIMPRREQUEST",
            columns: _vm.grid2.columns,
            gridHeight: _vm.grid2.height,
            data: _vm.grid2.data,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && !_vm.param.disabled,
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.param.disabled
                    ? _c("c-btn", { attrs: { label: "LBLSAVE", icon: "save" } })
                    : _vm._e(),
                  _vm.editable && !_vm.param.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.add2 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }